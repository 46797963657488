const React = require("react");

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.hash) {
    console.log("hash detected: " + location.hash);
    if (location.hash === "#positions") {
      window.setTimeout(() => {
        const positions = document.querySelector("#positions");
        if (positions) positions.scrollIntoView();
      }, 355);
    }
    return false;
  }

  const transitionDelay = 350;
  window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  return false;
};

exports.onClientEntry = () => {
  console.log("We've started!");
};

exports.wrapPageElement = ({ element }) => <>{element}</>;
exports.onRenderBody = ({ pathname, setBodyAttributes }) => {
  const bodyClassMap = {
    "/captured-on-camera/": "captured-on-camera start",
    // Add other paths and classes as needed
  };

  const bodyClass = bodyClassMap[pathname] || "";
  setBodyAttributes({ className: bodyClass });
};
